import styles from "./NewLandingPage.module.scss";
import BuySectionImage from "assets/images/buySectionImage.webp";
import CombinedImage from "assets/icons/combinedIcon.png";
import useModal from "utils/hooks/useModal";
import SendLinkModal from "organisms/SendLinkModal/SendLinkModal";

const BuySection = () => {
  const { handleModal, openModal, openVideoModal, handleVideoModal } =
    useModal();

  return (
    <div className={styles.buySection} id="buy">
      <div className={styles.imageSection}>
        <img src={BuySectionImage} className={styles.buyImage} />
      </div>

      <div className={styles.buyContentSection}>
        <p className={styles.buyTitle}>
          Buy on <span>ByzWiz</span>
        </p>
        <p className={styles.buyDesc}>
          Discover and buy the most unique, passion driven creations!
        </p>
        <p className={styles.buyDesc}>
          Curated Businesses - Trust, Quality and Reliability.
        </p>
        <p className={styles.buyDesc}>
          Transact with super talented Entrepreneurs.
        </p>
        <button className={styles.btn} onClick={handleModal}>
          <p>Download the ByzWiz App</p>
          <img src={CombinedImage} className={styles.combinedImage} />
        </button>
      </div>
      <SendLinkModal handleModal={handleModal} open={openModal} />
    </div>
  );
};
export default BuySection;
